.app-container {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.donatetion-container {
  margin-top: 2rem;
  padding-bottom: 4rem;
  width: 100%;
}

.user-info-container {
  border: 0.75px solid #8e8484;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-header-align-text {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.nav-logo {
  color: hsl(28, 88%, 62%);
  font-size: 1.25rem;
}

.logo-image {
  width: 3rem;
}

.user-info {
  padding: 1rem;
}

.user-info-details {
  padding: 1rem;
  border-top: 0.75px solid #8e8484;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pay-button {
  margin: 1rem;
}

.required-field {
  color: red;
  font-size: 12px;
}

.user-input-field-width {
  width: 50%;
}

.user-input-field-mr-3 {
  margin-top: 3rem;

  label:after {
    content: " *";
    color: red;
  }
}

.user-input-field-mr {
  margin-top: 0.3rem;
}

.user-input-field {
  display: flex;
  align-items: center;

  label {
    text-align: right;
    width: 25%;
    margin-right: 1rem;
  }

  input {
    width: 300px;
    border: 0.75px solid #8e8484;
    border-radius: 3px;
    padding: 0.5rem;
  }
}

.nav-cta {
  display: block;
  background-color: hsl(28, 88%, 62%);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  padding: 0.5rem 1.5rem;
  width: max-content;
  color: #fff;
}