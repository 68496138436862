body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1150px) {
  .user-info-details {
    display: flex;
    flex-direction: column;
  }

  .user-input-field-width {
    width: 98% !important;
  }

  .user-input-field-mr-3 {
    margin-top: 1rem !important;
  }

  .user-input-field {
    align-items: flex-start !important;
    flex-direction: column;

    label {
      text-align: left !important;
      width: 98% !important;
      margin-right: 0rem;
    }

    input {
      width: 98% !important;
    }
  }
  .pay-button {
    margin-left: 0 !important;
  }
}
